import React from 'react'
import { object } from 'prop-types'
import { PrismicRichText } from '@prismicio/react'

import CtaLink from './CtaLink'
import * as Styled from './styles/ProductGridItem.styles'
import { Link } from 'gatsby'
import { slugify } from '../../util/strings'

const ProductGridItem = ({
  grayBackground = false, // Added missing comma
  item: {
    product_link,
    product_image,
    product_company,
    product_name,
    product_description,
  },
}) => {
  // Compute new link based on product_detail_pages_allowed.
  const companyData = product_company.document.data

  const allowDetailPages = companyData.product_detail_pages_allowed === true

  const linkHref = allowDetailPages
    ? `${slugify(companyData.product_company)}/${slugify(product_name.text)}`
    : product_link.url

  const link = allowDetailPages
    ? {
        url: linkHref,
        type: 'product_detail_page',
        link_type: 'Document',
        id: null,
        uid: linkHref,
      }
    : product_link

  return (
    <Link
      to={allowDetailPages ? `/seal-of-recognition/${linkHref}` : linkHref}
      target={!allowDetailPages ? '_blank' : undefined}
      style={{ textDecorationLine: 'none' }}
    >
      <Styled.ProductRegion grayBackground={grayBackground}>
        <Styled.ProductImageContainer>
          <Styled.ProductImage
            src={product_image.url}
            alt={product_image.alt}
          />
        </Styled.ProductImageContainer>
        <Styled.ProductBrand>{companyData.product_company}</Styled.ProductBrand>
        <Styled.ProductName>
          <PrismicRichText field={product_name.richText} />
        </Styled.ProductName>
        <Styled.ProductDescription>
          <PrismicRichText field={product_description.richText} />
        </Styled.ProductDescription>
        <CtaLink
          text="Learn More About This Product"
          link={link}
          additionalStyles={Styled.additionalCtaStyles}
        />
      </Styled.ProductRegion>
    </Link>
  )
}

ProductGridItem.propTypes = {
  item: object,
}

export default ProductGridItem
