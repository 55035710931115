/* eslint-disable no-useless-escape */
import US_STATES from '../data/us-states.json'

export const formatCityAndState = (city, state) => {
  let formatted = city ? city : ''
  if (city && state !== 'N/A') {
    formatted += ', '
  }
  if (state !== 'N/A') {
    formatted += US_STATES.filter(usState => usState.name === state).pop()
      .abbreviation
  }
  if (formatted === '') {
    return null
  }
  return formatted
}

// thanks to https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
export const slugify = input => {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return input
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}
