import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import ProductDirectory from '../components/ProductDirectory'
import { BottomLineGoogleAdContainer } from '../components/styles/GoogleAdsCommon.styles'
import { paginationChange } from '../util/helper'
import AdSlot from '../components/AdSlot'
import AdHead from '../components/AdHead'

export const Head = ({ pageContext }) => {
  return (
    <AdHead adsData={pageContext.adsData} targets={pageContext.pageTargets} />
  )
}

const ProductsLandingPage = ({ data, pageContext }) => {
  const alphaSort = (a, b) => {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  }

  const doc = data.prismicProductLandingPage
  const types = data.allPrismicProductType.edges
  const categories = data.allPrismicProductCategory.edges
  const productsList = data.allPrismicProduct.edges
  const companies = data.allPrismicProductCompany.edges
  const settings = data.prismicGeneralSiteSettings?.data
  if (
    !doc ||
    !types ||
    !companies ||
    !categories ||
    !productsList ||
    !settings
  ) {
    return null
  }
  const twitterImage = doc.data.twitter_image?.url
    ? doc.data.twitter_image
    : null
  const facebookImage = doc.data.facebook_image?.url
    ? doc.data.facebook_image
    : null
  const seoTitle = doc.data.meta_title?.text
    ? doc.data.meta_title.text
    : 'Seal of Recognition'
  const seo_embed_code = doc.data.seo_embed_code?.text ?? null
  const bottomLineAdBlock =
    settings?.product_page_google_ad?.document?.data?.ad_slot_name
  const topLineAdBlock =
    settings?.product_page_top_line_google_ad?.document?.data?.ad_slot_name

  return (
    // <pre>{JSON.stringify(pageContext, false, 2)}</pre>
    <>
      <SEO
        seo={{
          description: doc.data.meta_description,
          keywords: doc.data.meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
        }}
      />
      <Layout>
        <ProductDirectory
          doc={doc}
          types={types}
          companies={companies}
          categories={categories}
          productsList={productsList}
          topLineAdBlock={topLineAdBlock}
          paginationChangeAction={paginationChange}
        />
        {bottomLineAdBlock && (
          <BottomLineGoogleAdContainer>
            <AdSlot divId={`div-${bottomLineAdBlock}`} />
          </BottomLineGoogleAdContainer>
        )}
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    prismicProductLandingPage {
      id
      uid
      data {
        title {
          text
        }
        body_text {
          richText
        }
        product_page_disclaimer {
          richText
        }
        cta_link_text
        seo_embed_code {
          text
        }
        cta_link {
          link_type
          type
          uid
          id
          url
        }
      }
    }
    allPrismicProduct(sort: { fields: first_publication_date, order: DESC }) {
      edges {
        node {
          id
          first_publication_date
          last_publication_date
          data {
            product_description {
              text
              richText
            }
            product_image {
              alt
              url
            }
            product_name {
              text
              richText
            }
            product_category {
              document {
                ... on PrismicProductCategory {
                  id
                  data {
                    product_category
                  }
                }
              }
            }
            product_company {
              document {
                ... on PrismicProductCompany {
                  id
                  data {
                    product_company
                    product_detail_pages_allowed
                  }
                }
              }
            }
            product_type {
              document {
                ... on PrismicProductType {
                  id
                  data {
                    product_type
                  }
                }
              }
            }
            product_link {
              url
              type
              link_type
              id
              uid
            }
          }
        }
      }
    }
    allPrismicProductType(sort: { fields: data___product_type, order: ASC }) {
      edges {
        node {
          id
          data {
            product_type
          }
        }
      }
    }
    allPrismicProductCompany(
      sort: { fields: data___product_company, order: ASC }
    ) {
      edges {
        node {
          id
          data {
            product_company
          }
        }
      }
    }
    allPrismicProductCategory(
      sort: { fields: data___product_category, order: ASC }
    ) {
      edges {
        node {
          id
          data {
            product_category
          }
        }
      }
    }
    prismicGeneralSiteSettings {
      data {
        product_page_top_line_google_ad {
          document {
            ... on PrismicGoogleAdTag {
              id
              data {
                ad_slot_name
              }
            }
          }
        }
        product_page_google_ad {
          document {
            ... on PrismicGoogleAdTag {
              id
              data {
                ad_slot_name
              }
            }
          }
        }
      }
    }
  }
`

export default ProductsLandingPage
